import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub, faLinkedin, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './Home.css';

function Home() {
  return (
    <div className="home-page">
      {/* Introduction Section */}
      <section className="intro-section">
        <h1>Sojol Rana</h1>
        <p className="tagline">Programmer | Open-Source Contributor | Entrepreneur</p>
        <p className="intro-description">
          Passionate about building high-quality software solutions and contributing to open-source projects.
          Skilled in various web technologies and always eager to learn new skills and technologies.
        </p>
      </section>

      {/* Sample Code Snippet Section */}
      <section className="code-section">
        <h3 className="section-title">Featured Code Snippet</h3>
        <div className="ide-container">
        <pre>
  {`// A simple C++ function to greet the world
#include <iostream>
using namespace std;

void greet(string name) {
    cout << "Hello, " << name << "!" << endl;
}

int main() {
    greet("Developer");
    return 0;
}
`}
</pre>
        </div>
      </section>

      <section className="projects-section">
        <h3 className="section-title">Recent Projects</h3>

        <div className="project-card">
          <h4>
            <a href="/" target="_blank" rel="noopener noreferrer" className="project-link">
              Portfolio Website
              <i className="fas fa-external-link-alt open-icon"></i>
            </a>
          </h4>
          <p>
            A responsive, GitHub-themed portfolio website built with React and CSS.
            Showcases my projects, skills, and blog posts.
          </p>
        </div>

        <div className="project-card">
          <h4>
            <a href="/#" target="_blank" rel="noopener noreferrer" className="project-link">
              Open-Source CLI Tool
              <i className="fas fa-external-link-alt open-icon"></i>
            </a>
          </h4>
          <p>
            Developed a command-line tool that automates project setup for web developers, streamlining their workflow.
          </p>
        </div>
      </section>


      {/* Social Media Links */}
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/sojolrana" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} className="social-icon linkedin" />
        </a>
        <a href="https://x.com/sojolrana_" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSquareXTwitter} className="social-icon xtwitter" />
        </a>
        <a href="https://github.com/sojolrana" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} className="social-icon github" />
        </a>
        <a href="https://facebook.com/imSojol" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} className="social-icon facebook" />
        </a>
      </div>
    </div>
  );
}

export default Home;
