import React, { useEffect, useState, useRef, useMemo } from 'react';
import './Resume.css';

function Resume() {
  // Memoize the skills array to avoid reinitializing on each render
  const skills = useMemo(() => [
    { name: "C++", level: 85 },
    { name: "Python", level: 80 },
    { name: "JavaScript", level: 75 },
    { name: "React", level: 70 },
    { name: "Node.js", level: 65 },
    { name: "AI/ML", level: 60 },
    { name: "Web Development", level: 85 },
  ], []);

  const [progress, setProgress] = useState(skills.map(() => 0)); // Track progress
  const [hasAnimated, setHasAnimated] = useState(skills.map(() => false)); // Track if animation happened
  const skillsRef = useRef(null);

  useEffect(() => {
    // Copy skillsRef.current to a local variable
    const skillsElement = skillsRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.getAttribute("data-index"));
          if (entry.isIntersecting && !hasAnimated[index]) {
            animateProgress(index, skills[index].level);
            setHasAnimated((prev) => {
              const newHasAnimated = [...prev];
              newHasAnimated[index] = true;
              return newHasAnimated;
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (skillsElement) {
      const skillElements = skillsElement.querySelectorAll('.skill-item');
      skillElements.forEach((element, index) => {
        element.setAttribute("data-index", index);
        observer.observe(element);
      });
    }

    return () => {
      if (skillsElement) {
        const skillElements = skillsElement.querySelectorAll('.skill-item');
        skillElements.forEach((element) => observer.unobserve(element));
      }
    };
  }, [hasAnimated, skills]);

  // Function to animate the progress bar and update the percentage
  const animateProgress = (index, level) => {
    let currentProgress = 0;

    const animate = () => {
      if (currentProgress < level) {
        currentProgress += 1;
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          newProgress[index] = currentProgress;
          return newProgress;
        });
        requestAnimationFrame(animate); // Continue the animation
      }
    };

    animate(); // Start the animation
  };

  return (
    <div className="resume-page">
      <section className="resume-section">
        <h3>Experience</h3>
        <div className="resume-item">
          <h4>Software Engineer <span> | SoftRid</span></h4>
          <p className="resume-date">January 2022 - Present</p>
          <p>Worked on multiple projects involving full-stack development, focusing on AI and machine learning integration.</p>
        </div>
        <div className="resume-item">
          <h4>Freelance Developer <span> | Fiverr</span></h4>
          <p className="resume-date">2019 - Present</p>
          <p>Completed various client projects involving website development, SMTP configuration, and email filtering solutions.</p>
        </div>
      </section>

      <section className="resume-section">
        <h3>Education</h3>
        <div className="resume-item">
          <h4>Bachelor of Science in Computer Science and Engineering <span> | Dhaka University of Engineering & Technology</span></h4>
          <p className="resume-date">2023 - Present</p>
        </div>
        <div className="resume-item">
          <h4>Diploma in Computer Technology <span> | Rajshahi Polytechnic Institute</span></h4>
          <p className="resume-date">2017 - 2022</p>
        </div>
      </section>

      <section className="resume-section">
        <h3>Volunteering</h3>
        <div className="resume-item">
          <h4>Additional Programming and Algorithms Secratary <span> | DUET Computer Society</span></h4>
          <p className="resume-date">October 2024 - Present</p>
          <p>Joined the DUET Computer Society as the Additional Programming and Algorithms Secretary, contributing to a collaborative and growth-focused community.</p>
        </div>
        <div className="resume-item">
          <h4>Assistant Joint Secretary <span> | English Language Club(ELC), DUET</span></h4>
          <p className="resume-date">October 2024 - Present</p>
          <p>Member of the DUET English Club, contributing to discussions, presentations, and events focused on enhancing communication skills and language proficiency.</p>
        </div>
        <div className="resume-item">
          <h4>Ambassador <span> | ICT Olympiad Bangladesh</span></h4>
          <p className="resume-date">November 2024 - Present</p>
          <p>Joined as an Ambassador at ICT Olympiad Bangladesh, promoting the organization’s initiatives and encouraging students' passion for technology and innovation while creating opportunities for young talents to showcase their ICT skills.</p>
        </div>
      </section>

      <section className="resume-section">
        <h3>Achievements</h3>
        <div className="resume-item">
          <h4>1st Runner's Up <span> | Intra DUET Programming Contest (IDPC) - 2024</span></h4>
          <p className="resume-date">March 2024</p>
          <p>Secured First Runners-Up position in the Intra Duet Programming Contest. A great opportunity to apply problem-solving skills and enhance coding abilities.</p>
        </div>
      </section>

      <section className="resume-section" ref={skillsRef}>
        <h3>Skills</h3>
        <div className="skills-list">
          {skills.map((skill, index) => (
            <div key={index} className="skill-item">
              <div className="skill-name">{skill.name}</div>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ width: `${progress[index]}%` }}
                >
                  {/* Show the progress percentage as animated text */}
                  <span className="progress-percent">{progress[index]}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Resume;
