import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        <h2>About Me</h2>
        <p>
          Hello, my name is <strong>Sojol Rana</strong>, and I am currently a Computer Science and Engineering student at
          Dhaka University of Engineering & Technology (DUET), specializing in Artificial Intelligence (AI),
          Machine Learning (ML), Python, and Backend Development.
        </p>

        <p>
          I am passionate about using technology to solve complex problems. I focus on developing scalable solutions through my expertise
          in Data Science, Machine Learning, Deep Learning, Cloud Computing, and DevOps. I have honed my skills in
          Python and C++ to build innovative applications that push the boundaries of what technology can achieve.
        </p>

        <p>
          Throughout my academic journey, I have had the opportunity to develop my skills, not only in technical areas but also in teamwork
          and leadership. As the 1st Runner-Up in the Intra DUET Programming Contest, I have demonstrated my problem-solving
          abilities and my commitment to excellence in coding.
        </p>

        <h3>Skills & Expertise</h3>
        <ul>
          <li><strong>Programming Languages:</strong> C++, Python, JavaScript</li>
          <li><strong>Technologies:</strong> React, Node.js, Cloud Computing, DevOps</li>
          <li><strong>Specializations:</strong> AI, ML, Deep Learning, Backend Development</li>
        </ul>

        <h3>Beyond Tech</h3>
        <p>
          When I'm not coding, I enjoy traveling, watching movies, and exploring different cuisines. I believe that
          maintaining a balance between professional and personal experiences fuels my creativity and drives my passion for innovation.
        </p>

        <h3>Let’s Connect</h3>
        <p>
          I am always open to networking, discussing new technologies, or collaborating on projects. Feel free to <strong><Link to="/contact">reach out</Link></strong> if you're interested in
          connecting or have any questions about my work.
        </p>

        <div class="quotes-section">
          <div class="quote">
            <span class="quote-symbol">“</span>
            <p class="quote-text">"First, solve the problem. Then, write the code." – John Johnson</p>
            <span class="quote-symbol">”</span>
          </div>
        </div>

      </div>
    </div>
  );
}

export default About;
