import React, { useState } from 'react';  // Import useState here
import { Link, NavLink } from 'react-router-dom';  // Import NavLink here
import './Footer.css';

function Footer() {
  const [isOpen] = useState(false);  // Fix: Added useState

  return (
    <footer className="footer">
      <nav className={`nav-links-bottoms ${isOpen ? 'open' : ''}`}>
        {/* Replace Link with NavLink for active class handling */}
        <Link to="/">Home</Link>
        <NavLink to="/about" activeClassName="active">
          About
        </NavLink>
        <NavLink to="/contact" activeClassName="active">
          Contact
        </NavLink>
      </nav>
      <div className="bottom-footer">
        &copy; {new Date().getFullYear()} Sojol Rana | All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
