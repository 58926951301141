import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';  // Import NavLink here
import HamburgerMenu from './HamburgerMenu';
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
        <h1 className="logo">
          <Link to="/">Sojol Rana</Link>
        </h1>
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        {/* Replace Link with NavLink for active class handling */}
        <Link to="/">Home</Link>
        <NavLink to="/about" activeClassName="active">
          About
        </NavLink>
        <NavLink to="/resume" activeClassName="active">
          Resume
        </NavLink>
        <NavLink to="/gallery" activeClassName="active">
          Gallery
        </NavLink>
        <NavLink to="/contact" activeClassName="active">
          Contact
        </NavLink>
      </nav>
      <HamburgerMenu toggleMenu={toggleMenu} isOpen={isOpen} />
    </header>
  );
}

export default Header;