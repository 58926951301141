import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import routesConfig from "./config/routesConfig"; // Import routesConfig
import Header from "./components/Header"; // Import Header
import Footer from "./components/Footer"; // Import Footer
import Home from "./pages/Home"; // Import Home page
import About from "./pages/About"; // Import About page
import Resume from "./pages/Resume"; // Import Resume page
import Gallery from "./pages/Gallery"; // Import Gallery page
import Contact from "./pages/Contact"; // Import Contact page
import NotFound from "./pages/NotFound"; // Import NotFound page
import "./App.css"; // Import styles

function App() {
  return (
    <Router>
      <RemoveTrailingSlash />
      <div className="app-container">
        <Helmet>
          {/* Global Metadata */}
          <title>Sojol Rana - Portfolio & Blog</title>
          <meta
            name="description"
            content="Sojol Rana's personal portfolio showcasing programming skills, blog posts, and software development projects."
          />
          <meta
            name="keywords"
            content="portfolio, Sojol Rana, web development, AI, machine learning, software engineer, blog"
          />
          <meta name="author" content="Sojol Rana" />
          <meta name="robots" content="index, follow" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Sojol Rana - Portfolio & Blog" />
          <meta
            property="og:description"
            content="Visit my portfolio showcasing my work and projects on AI, web development, and more."
          />
          <meta
            property="og:image"
            content="https://sojolrana.com/images/SojolRana-OG.jpg"
          />
          <meta property="og:image:alt" content="Sojol Rana" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content="https://sojolrana.com" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="en_US" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@sojolrana_" />
          <meta name="twitter:title" content="Sojol Rana - Portfolio & Blog" />
          <meta
            name="twitter:description"
            content="Explore my portfolio to learn about my work and blog on web development and AI."
          />
          <meta
            name="twitter:image"
            content="https://sojolrana.com/images/SojolRana-OG.jpg"
          />
        </Helmet>

        <Header />
        <main className="main-content">
          <Breadcrumbs />
          <PageTitle />
          <Routes>
            {routesConfig.map(({ path, element }, index) => (
              <Route
                key={index}
                path={path}
                element={
                  {
                    "/": <Home />,
                    "/about": <About />,
                    "/resume": <Resume />,
                    "/gallery": <Gallery />,
                    "/contact": <Contact />,
                    "*": <NotFound />,
                  }[path]
                }
              />
            ))}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// RemoveTrailingSlash Component
function RemoveTrailingSlash() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname.endsWith("/")) {
      const newPathname = location.pathname.slice(0, -1);
      window.history.replaceState({}, "", newPathname + location.search);
    }
  }, [location]);

  return null;
}

// Breadcrumbs Component
function Breadcrumbs() {
  const location = useLocation();
  const pathnameParts = location.pathname.split("/").filter(Boolean);

  const breadcrumbItems = pathnameParts.map((part, index) => ({
    "@type": "ListItem",
    position: index + 2,
    name: part.charAt(0).toUpperCase() + part.slice(1),
    item: `${window.location.origin}/${pathnameParts.slice(0, index + 1).join("/")}`,
  }));

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: `${window.location.origin}/`,
      },
      ...breadcrumbItems,
    ],
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema)}
      </script>
    </Helmet>
  );
}

// Page Title Component
const PageTitle = () => {
  const location = useLocation();
  const titles = {
    "/": "Sojol Rana",
    "/about": "About",
    "/resume": "Resume",
    "/gallery": "Gallery",
    "/contact": "Contact",
  };
  const title = titles[location.pathname.replace(/\/+$/, "")] || "Page not found!";

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "G-D8H2RLJHGZ", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return (
    <Helmet>
      <title>{location.pathname === "/" ? "Sojol Rana" : `${title} | Sojol Rana`}</title>
      <link
        rel="canonical"
        href={`${window.location.origin}${location.pathname.replace(/\/+$/, "")}`}
      />
    </Helmet>
  );
};

export default App;